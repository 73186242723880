section h2.section-heading

{margin-top:0;margin-bottom:15px;font-size:60px}

.singleBookContainer

{margin-bottom:30px;}

.subpageHeading

{padding-top:50px;padding-bottom:30px}

.row

{margin-left:-15px;margin-right:-15px}

.text-center

{text-align:center}

@media (min-width:350px)

{.bookContainer

{display:block}

}

@media (min-width:700px)

{.bookContainer

{display:grid;grid-template-columns:1fr 1fr}

}

@media (min-width:800px)

{.bookContainer

{display:block}

}

@media (min-width:1166px)

{.bookContainer

{width:80%;margin-right:auto;margin-left:auto;display:grid;grid-template-columns:1fr 1fr}

}

@media (min-width:2000px)

{.bookContainer

{width:80%;margin-right:auto;margin-left:auto;display:grid;grid-template-columns:1fr 1fr 1fr}

}

.bookCoverImage

{width:14vw;margin:0 auto;box-shadow:1px 1px 1px 1px gray;margin-bottom:20px;margin-top:20px;border-radius:10px; min-width:250px; }

.bookStoreIcon

{height:40px;width:40px;border-radius:8px;box-shadow:1px 1px 1px 1px gray}

.bookContainer

{margin-bottom:50px;text-align:center}

.list-inline

{padding-left:0;list-style:none;margin-left:-5px}

.list-inline li

{display:inline-block;padding-left:5px;padding-right:5px}

