.patentInfo
{display:grid;grid-template-columns:1fr 1fr}
#patents .singleProjectContainer
{margin-bottom:140px}
#patents .projectIcons
{display:grid;grid-template-columns:1fr 1fr}
 @media (min-width: 1100px) 
{#patents .singleProjectContainer
{width:70%;}
}
