#media .singleLectureContainer .websiteImage 
{
    width:100%;
    aspect-ratio: 16/9;
}

#producing .singleLectureContainer .websiteImage 
{
    width:100%;
    aspect-ratio: 16/9;
}

.YoutubePreview{
    width:100%;
    height:auto;
    display:block;
    box-shadow:1px 1px 1px 1px gray;
    border-radius:10px;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:50px;
    min-width:200px;
    aspect-ratio: 16/9;
}

 .singleLectureContainer
{width:70%;margin-left:auto;margin-right:auto;margin-bottom:100px}
.gitIcons a
{font-family:Raleway,sans-serif;color:#666;font-weight:100}
 @media (min-width: 1100px) 
{.singleLectureContainer
{width:70%;}
}
