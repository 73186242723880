.websiteImage{
    width:70%;
    height:auto;
    display:block;
    box-shadow:1px 1px 1px 1px gray;
    border-radius:10px;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:50px;
    min-width:200px;
}



#websites .projectIcons
{display:grid;grid-template-columns:1.5fr 1fr}
.siteUrl
{text-align:left}
@media (max-width:600px)
{.websitesContainer
{width:100%;margin-left:auto;margin-right:auto}
#websites .singleProjectContainer
{margin-bottom:150px;width:100%}
}
@media (min-width:600px)
{.websitesContainer
{width:80%;margin-left:auto;margin-right:auto}
#websites .singleProjectContainer
{margin-bottom:150px;width:80%}
}
#socialContribution .singleProjectContainer
{margin-bottom:150px;width:80%}

.collectedFrom {
    color:lightgray;
}
